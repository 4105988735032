import classNames from "classnames";
import Image from "next/image"
import { useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { HiArrowRight } from 'react-icons/hi';
import SbEditable from "storyblok-react";
import { extractDimensionsFromFilename } from "utils/img-helpers";

const DevelopmentTimeline = ({blok}) => {
    return (
        <SbEditable content={blok}>
            <section className="bg-blue-700" id={blok.section_id}>
                <div className="container relative py-8 lg:px-20">
                    <h2 className="text-white font-semiBold text-4xl lg:text-6xl leading-none">{blok.title}</h2>
                    <div className="py-8 max-w-xs">
                        <Legend/>
                    </div>
                    <div className="lg:hidden flex flex-col gap-8 lg:pt-8">
                        {blok.projects.map((project) => (
                            <Project 
                                key={project._uid}
                                project={project}
                            />
                        ))}
                    </div>
                    <div className="hidden lg:grid grid-cols-2">
                        <div className="flex flex-col gap-8">
                            {blok.projects.map((project) => (
                                <Project 
                                    key={project._uid}
                                    project={project}
                                />
                            ))}
                        </div>
                        <div className="custom-scrollbar overflow-auto flex flex-col gap-8 pb-8">
                            {blok.projects.map((project) => (
                                <ProjectImage 
                                    key={project._uid}
                                    project={project}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </SbEditable>
    )
}

export default DevelopmentTimeline

const Project = ({project}) => {

    const [isOpen, setIsOpen] = useState(false)
    const {height, width} = extractDimensionsFromFilename(project.image.filename)

    return (
        <div className="lg:flex lg:h-30.75">
            <button 
                className="text-white w-full flex flex-col lg:flex-row lg:flex-wrap lg:items-center gap-1 lg:gap-x-16"
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className="font-gilroyBold text-2xl leading-none">{project.title}</h3>
                <div className="flex gap-3">
                    <div className="flex items-center gap-1">
                        <Image
                            src='/icons/Icon_CalendarDots.svg'
                            alt='calendar icon'
                            width={20}
                            height={20}
                        />
                        <span className="text-sm leading-none text-left">{project.year}</span>
                    </div>
                    <div className="flex items-center gap-1">
                        <Image
                            src='/icons/Icon_BuildingApartment.svg'
                            alt='building apartment icon'
                            width={20}
                            height={20}
                        />
                        <span className="text-sm leading-none text-left">{project.unit_info}</span>
                    </div>
                </div>
                <div className="flex gap-2.5 items-center mt-3 lg:hidden lg:mt-0">
                    <span className="underline">Mehr sehen</span>
                    <HiChevronDown className={classNames('lg:hidden', {
                        'transform rotate-180': isOpen
                    })}/>
                    <HiArrowRight className="hidden lg:block"/>
                </div>            
            </button>
            <div className={classNames("custom-scrollbar w-full lg:hidden overflow-auto mt-2 lg:mt-0 pb-7", {
                    'hidden lg:block': !isOpen,
                    'block': isOpen
                })}>
                    {/* provided img sould be 246px high */}
                    <Image
                        src={project.image.filename}
                        alt={project.image.alt}
                        width={width/2}
                        height={height/2}
                        layout='fixed'
                    />
            </div>
        </div>
    )
}

const ProjectImage = ({project}) => {
    const {height, width} = extractDimensionsFromFilename(project.image.filename)
    return (
        <Image
            src={project.image.filename}
            alt={project.image.alt}
            width={width/2}
            height={height/2}
            layout='fixed'
        />
    )
}

const Legend = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <button 
                className="bg-white text-blue-700 p-2 w-full flex justify-between items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="font-gilroyMedium text-sm leading-none">Beschriftung</span>
                <HiChevronDown className={classNames("text-blue-700 text-lg", {
                    'transform rotate-180': isOpen
                })}/>
            </button>
            <div className={classNames('bg-white p-2', {
                'hidden': !isOpen
            })}>
                <ul className="flex flex-col gap-3">
                    <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 bg-blue-200"/>
                        <p>Planungszeitraum</p>
                    </li>
                    <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 border border-black"/>
                        <p>Instandsetzung</p>
                    </li>
                    <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 bg-orange-full"/>
                        <p>Ersatzneubau</p>
                    </li>
                    <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 bg-orange-full opacity-50"/>
                        <p>Gesamtsanierung</p>
                    </li>
                    <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 flex justify-center items-center text-base font-gilroyBold">A</div>
                        <p>Studienauftrag, Wettbewerb</p>
                    </li>
                    <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 flex justify-center items-center text-base font-gilroyBold">B</div>
                        <p>Rahmenbedingungen</p>
                    </li>
                     <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 flex justify-center items-center text-base font-gilroyBold">C</div>
                        <p>
                            Verschiebung Hotzsteig<br/>
                            {'(Verfahren mit Tiefbauamt)'}
                        </p>
                    </li>
                     <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 flex justify-center items-center text-base font-gilroyBold">D</div>
                        <p>
                            Planungsinstrument, z.B.<br/>
                            Gestaltungsplan
                        </p>
                    </li>
                    <li className="flex items-center text-blue-700 text-xs leading-none gap-2">
                        <div className="w-4 h-4 flex justify-center items-center text-base font-gilroyBold">E</div>
                        <p>
                            Mitwirkungsanlass BGL & GBRZ
                        </p>
                    </li>
                </ul>
            </div>
        </>
    )
}